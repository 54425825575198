<template>
  <base-layout page-title="Update Wi-Fi Credentials">
    <template v-slot:main-content>
      <!-- Show when VEDA is online -->
      <div v-if="isVEDAOnline" class="veda-online">
        <ion-card class="form-card">
          <ion-card-content>
            <div v-if="!isSubmit" class="form-div">
              <!-- WiFi Update Form -->
              <h1 class="ion-padding ion-text-center">ENTER NEW Wi-Fi CREDENTIALS</h1>
              <form class="ion-padding" @submit.prevent="confirmUpdate">
                <ion-list>
                  <ion-item>
                    <ion-label position="fixed">SSID</ion-label>
                    <input class="form-control" type="text" v-model="ssid" placeholder="SSID">
                  </ion-item>
                  <ion-item>
                    <ion-label position="fixed">Password</ion-label>
                    <input class="form-control" type="text" v-model="password" placeholder="PASSWORD">
                  </ion-item>
                  <br>
                  <ion-button type="submit" expand="block" fill="outline">UPDATE Wi-Fi CREDENTIALS</ion-button>
                </ion-list>
              </form>
            </div>
            <!-- Confirmation -->
            <div v-else class="confirm-div">
              <h1>CONFIRM UPDATE Wi-Fi CREDENTIALS</h1>
              <ion-card>
                <ion-card-content>
                  <ion-text>
                    Updating the Wi-Fi settings will take the VEDA off-line until the network with these credentials is available.
                  </ion-text>
                  <ion-list>
                    <ion-item>
                      <ion-label>SSID: <b>{{ ssid }}</b></ion-label>
                    </ion-item>
                    <ion-item>
                      <ion-label>password: <b>{{ password }}</b></ion-label>
                    </ion-item>
                    <ion-button @click="isVEDAOnline = true" size="small" expand="block" color="secondary">UPDATE</ion-button>
                  </ion-list>
                </ion-card-content>
              </ion-card>
            </div>
          </ion-card-content>
        </ion-card>
      </div>

      <!-- Show when VEDA is offline -->
      <div v-else class="veda-offline">
        <ion-card>
          <ion-card-content>
            <ion-text color="dark" class="card-content-info">
              The VEDA is not currently online. In order to update Wi-Fi Settings you must first connect the VEDA with the internet. For doing so, please create a Wifi Access Point connected to the Internet with the following details and VEDA will automatically connect with it.
            </ion-text>
            <ion-list>
              <ion-item>
                <ion-label>
                  <h2>SSID</h2>
                  <p>{{ssid}}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label>
                  <h2>Password</h2>
                  <p>{{password}}</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button @click="pingVeda()" size="small" expand="block">RETRY</ion-button>
            </ion-col>
            <!-- <ion-col>
              <ion-button href="/factory-reset" size="small" expand="block" color="danger">FACTORY RESET</ion-button>
            </ion-col> -->
          </ion-row>
        </ion-grid>
      </div>
    </template>
  </base-layout>
</template>

<script lang="ts">
import BaseLayout from './BaseLayout.vue'
import { defineComponent } from 'vue'

// This updates the Wifi settings of the VEDA
// await fetch("https://cddevapi.village.energy/sat/v1/dvi/iot?null=", {
//     "credentials": "include",
//     "headers": {
//         "Content-Type": "application/json",
//         "Authorization": "Bearer {accessToken}"
//     },
//     "body": "{\"satAction\":\"setWifi\",\"deviceId\":\"b8f009907af8\",\"param1\":\"Oxygen\",\"param2\":\"29999125\",\"debug\":true}",
//     "method": "PUT",
//     "mode": "cors"
// });

import {
  IonButton,
  IonCard,
  IonCardContent,
  loadingController,
  alertController
} from "@ionic/vue"
import { userService } from "@/services/userService"
import { vedaService } from '@/services/vedaService'

export default defineComponent({
  name: 'UpdateWiFiSettings',
  components: {
    IonButton,
    IonCard,
    IonCardContent,
    BaseLayout
  },
  data: () => {
    return {
      isVEDAOnline: true,
      isSubmit: false,
      ssid: '',
      password: ''
    }
  },
  methods: {
    async confirmUpdate() {
      const alert = await alertController.create({
        header: 'Confirm',
        message: 'Setting the wrong credentials will take the device offline and you will have to reconnect it manually',
        buttons: [
          {
            role: 'cancel',
            text: 'Cancel',
          },
          {
            text: 'Update Credentials',
            handler: () => {
              this.submitForm()
            }
          }
        ]
      })
      alert.present()
    },
    async submitForm() {
      // hide form when button is hit
      const loading = await loadingController
              .create({
                cssClass: 'my-custom-class',
                message: 'Please wait...',
                duration: 3000,
              })
      await loading.present()
      userService.updateWifiAp({
        satAction: "setWifi",
        deviceId: vedaService.getVedaDeviceId(),
        param1: this.ssid,
        param2: this.password,
        debug: vedaService.getDebug()
      }).then((data) => {
        // this.isSubmit = true
        loading.dismiss()
        if (data.data.status) {
          // alert('Wi-Fi credentials updated successfully')
          userService.openToast('Wi-Fi credentials updated successfully', 'success')
        } else {
          // alert(data.data.data)
          userService.openToast(data.data.data, 'warning')
        }
        console.log(data)
      }, (err) => {
        console.log(err)
        loading.dismiss()
      })
    },
    async pingVeda() {
      // this.$emit('wifi-test')
      const deviceId = vedaService.getVedaDeviceId()
      const res = await vedaService.pingDeviceWifi(deviceId, true)
      if (res.isError) {
        // error while fetching response
        userService.openToast('Unexpected error while pinging VEDA', 'danger')
      } else if (!res.isMqttPresent) {
        // data is empty
        userService.openToast('Wi-Fi test failed', 'danger')
      } else {
        userService.openToast('Wi-Fi test success', 'success')
      }
    }
  },
  created() {
    console.log(this.ssid)
    const vedaDetails = vedaService.getVedaDetails()
    if (vedaDetails?.device) {
      this.ssid = vedaDetails.device.wifiSsid || vedaDetails.device.defaultWifiSsid
      this.password = vedaDetails.device.wifiPassword || vedaDetails.device.defaultWifiPassword
    }
    setInterval(() => {
      this.isVEDAOnline = vedaService.getIsVedaOnline()
    }, 1000)
  }
})
</script>

<style scoped>
  .card-content-info {
    font-size: 18px;
  }
  .form-card{
    max-width: 500px;
    margin: 20px auto;
  }
  .form-control{
    width: 100%;
    height: 40px;
    border-color: #e7e7e7;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  ion-button {
    text-transform: none;
  }
</style>
