
import BaseLayout from './BaseLayout.vue'
import { defineComponent } from 'vue'

// This updates the Wifi settings of the VEDA
// await fetch("https://cddevapi.village.energy/sat/v1/dvi/iot?null=", {
//     "credentials": "include",
//     "headers": {
//         "Content-Type": "application/json",
//         "Authorization": "Bearer {accessToken}"
//     },
//     "body": "{\"satAction\":\"setWifi\",\"deviceId\":\"b8f009907af8\",\"param1\":\"Oxygen\",\"param2\":\"29999125\",\"debug\":true}",
//     "method": "PUT",
//     "mode": "cors"
// });

import {
  IonButton,
  IonCard,
  IonCardContent,
  loadingController,
  alertController
} from "@ionic/vue"
import { userService } from "@/services/userService"
import { vedaService } from '@/services/vedaService'

export default defineComponent({
  name: 'UpdateWiFiSettings',
  components: {
    IonButton,
    IonCard,
    IonCardContent,
    BaseLayout
  },
  data: () => {
    return {
      isVEDAOnline: true,
      isSubmit: false,
      ssid: '',
      password: ''
    }
  },
  methods: {
    async confirmUpdate() {
      const alert = await alertController.create({
        header: 'Confirm',
        message: 'Setting the wrong credentials will take the device offline and you will have to reconnect it manually',
        buttons: [
          {
            role: 'cancel',
            text: 'Cancel',
          },
          {
            text: 'Update Credentials',
            handler: () => {
              this.submitForm()
            }
          }
        ]
      })
      alert.present()
    },
    async submitForm() {
      // hide form when button is hit
      const loading = await loadingController
              .create({
                cssClass: 'my-custom-class',
                message: 'Please wait...',
                duration: 3000,
              })
      await loading.present()
      userService.updateWifiAp({
        satAction: "setWifi",
        deviceId: vedaService.getVedaDeviceId(),
        param1: this.ssid,
        param2: this.password,
        debug: vedaService.getDebug()
      }).then((data) => {
        // this.isSubmit = true
        loading.dismiss()
        if (data.data.status) {
          // alert('Wi-Fi credentials updated successfully')
          userService.openToast('Wi-Fi credentials updated successfully', 'success')
        } else {
          // alert(data.data.data)
          userService.openToast(data.data.data, 'warning')
        }
        console.log(data)
      }, (err) => {
        console.log(err)
        loading.dismiss()
      })
    },
    async pingVeda() {
      // this.$emit('wifi-test')
      const deviceId = vedaService.getVedaDeviceId()
      const res = await vedaService.pingDeviceWifi(deviceId, true)
      if (res.isError) {
        // error while fetching response
        userService.openToast('Unexpected error while pinging VEDA', 'danger')
      } else if (!res.isMqttPresent) {
        // data is empty
        userService.openToast('Wi-Fi test failed', 'danger')
      } else {
        userService.openToast('Wi-Fi test success', 'success')
      }
    }
  },
  created() {
    console.log(this.ssid)
    const vedaDetails = vedaService.getVedaDetails()
    if (vedaDetails?.device) {
      this.ssid = vedaDetails.device.wifiSsid || vedaDetails.device.defaultWifiSsid
      this.password = vedaDetails.device.wifiPassword || vedaDetails.device.defaultWifiPassword
    }
    setInterval(() => {
      this.isVEDAOnline = vedaService.getIsVedaOnline()
    }, 1000)
  }
})
